<template>
	<div class="row">
    <div class="col-6 text-center">
			<h3>{{getTrans('messages.studio')}}</h3>
		</div>
    <div class="col-6 text-center">
			<h3>{{getTrans('messages.model')}}</h3>
		</div>
		<div class="col-6 text-center">
			<h3>{{getUser.currentBalance + ' '+ getTrans('messages.tokens')}} </h3>
		</div>
		<div class="col-6 text-center">
			<h3>{{balance + ' ' + getTrans('messages.tokens')}}</h3>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-12 text-center">
			<base-button v-if="type" :type="type=='give'?'danger':'secondary'" @click="type='give'">
				{{getTrans('messages.give')}}
			</base-button>
			<base-button v-if="type" :type="type=='collect'?'danger':'secondary'" @click="type='collect'">
				{{getTrans('messages.collect')}}
			</base-button>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-6 col-md-4 col-lg-2 text-center" v-for="val in values" :key="val.value">	
			<DivaSelectableCard :selected="val.value==selectedValue" @click="selectedValue=val.value;" class="h-100">
				<span class="h3">{{val.value}} {{getTrans('messages.tokens')}}</span>
			</DivaSelectableCard>	
		</div>
		<div class="col-6 col-md-4 col-lg-2 text-center">
			<DivaSelectableCard :selected="customValue==selectedValue && selectedValue>0" @click="selectedValue=customValue;" class="h-100">
				<span class="m-2"><input :placeholder="'Custom'" v-model="customValue" @change="selectedValue=customValue"/></span> <span class="h3 m-auto">{{getTrans('messages.tokens')}}</span>
			</DivaSelectableCard>
		</div>
	</div>
	<div class="row">
		<div class="col-12 text-center">
			<base-button :type="'primary'" @click="give">{{getTrans('messages.'+type)}}</base-button>
		</div>
	</div>
</template>

<script>
import DivaSelectableCard from "@/components/Diva/DivaSelectableCard.vue"
import { mapGetters } from 'vuex'
export default {
	name: 'TokenSettings',
	components: {
		DivaSelectableCard,
	},
	props: {
		user: {
			type: Object,
		},
    getError: Function,
	},
	data() {
		return {
			type: 'give',
			customValue: "",
			selectedValue: "",
			values: [{
					value: 25,
				},
				{
					value: 50,
				},
				{
					value: 100,
				},
				{
					value: 250,
				},
				{
					value: 500,
				},
			],
		}
	},
	computed: {
		...mapGetters('User', ['getUser']),
		balance() {
			if (this.user?.currentBalance) {
				return this.user?.currentBalance;
			} else {
				return '-'
			}
		}
	},
	methods: {
		give() {
			let studio = this.getUser;
			let tokens = this.type == 'give' ? this.selectedValue : -1 * this.selectedValue;
			if ((tokens < 0 && (Math.round(this.user.currentBalance) + tokens < 0))) {
				this.$swal.fire(
					this.getTrans('messages.balance_low'),
					"",
					'error'
				);
			} else if (studio.currentBalance <= 0 && tokens > 0) {
				this.$swal.fire(
					this.getTrans('messages.not_enough_tokens'),
					"",
					'error'
				);
			} else {
				this.$store.dispatch('Model/addTokens', {
					id: this.user.id,
					tokens: tokens
				}).then(() => {
					this.$swal.fire(
						this.getTrans('messages.transfer_complete'),
						"",
						'success'
					);
					this.$store.dispatch("Model/getOne", this.user.id);
				});
			}
		}
	}
}
</script>